import { getBoolean } from '@helpers/storage';
import { create } from 'zustand'

const initialState = {
    storeQueries: getBoolean("privacy.storeQueries", true),
    storeVisit: getBoolean("privacy.storeVisit", true),
    // NOTE: Uncomment to enable opening on first visit 
    // warnOpen: !getBoolean("privacy.warnEverOpened") || false,
    warnOpen: false,
}

function resetLocalStorage() {
    for (let i = 0, len = localStorage.length; i < len; i++) {
        let key = localStorage.key(i);
        if (key.startsWith("privacy.")) {
            localStorage.removeItem(key);
        }
    }
}

const useStore = create((set) => ({
    // State
    ...initialState,

    // Methods
    setStoreQueries: (enabled) => set(() => { localStorage.setItem("privacy.storeQueries", enabled); return { storeQueries: enabled } }),
    setStoreVisit: (enabled) => set(() => { localStorage.setItem("privacy.storeVisit", enabled); return { storeVisit: enabled } }),
    setWarnOpen: (open) => set({ warnOpen: open }),
    reset: () => set(() => { resetLocalStorage(); return initialState }),
}))

export default useStore;