function ConditionalRender({ children, condition }) {

    if (!condition) {
        // not logged in so redirect to login page with the return url
        return <div></div>
    }

    // authorized so return child components
    return children;
}

export default ConditionalRender;
