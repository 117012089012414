import { checkTokenValidity } from '@helpers/api';
import { Navigate } from 'react-router-dom';


function PrivateRoute({ children }) {
    const token = localStorage.getItem("token")

    if (!token) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/" />
    }

    // authorized so return child components
    return children;
}

export default PrivateRoute;
