import AdbIcon from '@mui/icons-material/Adb';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link } from "react-router-dom";
import LoginForm from '@components/LoginForm.jsx';

import { logout } from "@helpers/api";

import useAPI from "@stores/api";
import usePrivacy from "@stores/privacy";
import ConditionalRender from '@components/ConditionalRender';

const pages = [{ name: "Búsqueda", path: "/" }, { name: "Contacto", path: "/about" }, { name: "Documentación", path: "/docs/site/", requiresAuth: true, external: true }];

const settings = { ADMINPANEL: { text: 'Panel de Administración', path: "/admin" }, LOGOUT: { text: 'Cerrar sesión' } };

function ResponsiveAppBar() {

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const loginForm = React.useRef(null);
  const privacyWarnOpen = usePrivacy((state) => state.warnOpen);
  const setPrivacyWarnOpen = usePrivacy((state) => state.setWarnOpen);

  const token = useAPI((state) => state.token);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    if (token) {
      setAnchorElUser(event.currentTarget);
    } else {
      loginForm.current.openModal();
    }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleSelectionUserMenu = (e, setting) => {
    handleCloseUserMenu();

    switch (setting) {
      case settings.ADMINPANEL:
        break;
      case settings.LOGOUT:
        logout();
        break;
      default:
        console.error("User settings: Uknown option");
    }

  };


  const specialFunctions = [{ name: "Privacidad", function: (e) => setPrivacyWarnOpen(!privacyWarnOpen) }];

  const logo = <div onClick={() => { window.location.href = "/" }}>
    <h1 className='rightTitle'>
      <span className='titlecolor'>Repo</span>Search
    </h1>
  </div>;

  return (
    <div>
      <AppBar position="static" color="inherit">
        <Container maxWidth="l">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {logo}
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="primary"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <ConditionalRender key={page.name} condition={page.requiresAuth ? localStorage.getItem("token") : true}>
                  <Link to={page.path} reloadDocument={page.external}>
                      <MenuItem onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{page.name}</Typography>
                      </MenuItem>
                    </Link>
                  </ConditionalRender>
                ))}
                {
                  specialFunctions.map((special) => (
                    <div onClick={special.function} key={special.name}>
                      <MenuItem onClick={handleCloseNavMenu}>
                        <Typography textAlign="center">{special.name}</Typography>
                      </MenuItem>
                    </div>
                  ))
                }
              </Menu>
            </Box>
            <Typography
              variant="h9"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              {logo}
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              {pages.map((page) => (
                <ConditionalRender key={page.name} condition={page.requiresAuth ? localStorage.getItem("token") : true}>
                  <Link to={page.path} reloadDocument={page.external}>
                    <Button
                      onClick={handleCloseNavMenu}
                      sx={{ my: 2, display: 'block' }}
                    >
                      {page.name}
                    </Button>
                  </Link>
                </ConditionalRender>
              ))}
              {
                specialFunctions.map((special) => (
                  <div onClick={special.function} key={special.name}>
                    <Button onClick={handleCloseNavMenu}
                      sx={{ my: 2, display: 'block' }}
                    >
                      {special.name}
                    </Button>
                  </div>
                ))
              }
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Abrir ajustes">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {Object.values(settings).map((setting) => (
                  <MenuItem key={setting.text} onClick={(e) => handleSelectionUserMenu(e, setting)}>
                    {
                      setting.path ?
                        <Link to={setting.path} style={{ textDecoration: 'none', color: "unset" }}>
                          <Typography textAlign="center">{setting.text}</Typography>
                        </Link>
                        :
                        <Typography textAlign="center">{setting.text}</Typography>
                    }
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <LoginForm ref={loginForm} />
    </div>
  );
}
export default ResponsiveAppBar;