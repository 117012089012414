import { AppBar, Toolbar } from '@mui/material';
import { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import './App.css';
import About from './pages/about';
import Home from './pages/index';
import Admin from './pages/admin';
import ResponsiveAppBar from '@components/ResponsiveAppBar';
import DataUsageWarn from '@components/DataUsageWarn';
import PrivateRoute from '@components/PrivateRoute';
import { registerVisit } from '@helpers/api';

//import styles from './mystyle.module.css';//used to add css to global app, couldnt find any better solution to adding margin to a div for example
//import { BrowserRouter as Router} from 'react-router-dom';

export default function App() {

  useEffect(() => {
    registerVisit();
  }, []);

  return (
    <Router>
      <div>
        <ResponsiveAppBar />
        <DataUsageWarn />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/admin" element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          } />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  )
}

