import { LineChart } from '@mui/x-charts';
import { compareDatedObjects } from '@helpers/date';

const QueriesStatisticsLineChart = ({ data }) => {

    return (
        <div className='linechart-with-label'>
            <LineChart
                xAxis={[{
                    id: 'Fecha',
                    data: data ? data.queries.sort(compareDatedObjects).map(v => new Date(v.date)) : [new Date()],
                    scaleType: "time",
                    valueFormatter: (date) => date.toISOString().split('T')[0],
                }]}
                series={[
                    {
                        data: data ? data.queries.sort(compareDatedObjects).map(v => v.queries.reduce((total, queries) => total + (queries.type == 'memories' ? 1 : 0), 0)) : [0],
                        label: "Metadata",
                        id: "Metadata",
                    },
                    // {
                    //     data: data ? data.queries.sort(compareDatedObjects).map(v => v.queries.reduce((total, queries) => total + (queries.type == 'semantic' ? 1 : 0), 0)) : [0],
                    //     label: "Semántica",
                    //     id: "Semántica",
                    // },
                    {
                        data: data ? data.queries.sort(compareDatedObjects).map(v => v.queries.reduce((total, queries) => total + (queries.type == 'mixed' ? 1 : 0), 0)) : [0],
                        label: "Mixta",
                        id: "Mixta",
                    }
                ]}
                width={500}
                height={300}
            />
            <p>
                Número de consultas por día (separadas por tipo)
            </p>
        </div>
    );
}

export default QueriesStatisticsLineChart;