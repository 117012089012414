import DropItem from '@components/DropItem';
import useFilters from '@stores/filters';

const DateFilterContent = ({ sectionName, visible, toggle, onFilterChange }) => {
  const date = useFilters((state) => state.fecha);
  const fechaOperacion = useFilters((state) => state.fechaOperacion);
    return (
      <>
        <DropItem sectionName={sectionName} toggle={toggle} visible={visible}></DropItem>
        <div className="droppableFilterContent" style={{ display: visible ? "flex" : "none" }}>
          <div className='droppableFilterContentWrapper'>
            <label>Buscar por fecha</label>
            <select name="fechaOperacion" id="fechaOperacion" onChange={onFilterChange} value={fechaOperacion}>
              <option value=">=">Mayor o igual que</option>
              <option value="==">Igual que</option>
              <option value="<=">Menor o igual que</option>
              <option value="!=">distinto a</option>
            </select>
            <input type="date" id="fecha" name="fecha" onChange={onFilterChange} value={date}/>
            <div className='spacer'></div>
          </div>
        </div>
  
      </>
    )
  };

export default DateFilterContent;