import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const About = () => {

  return (
    <div className="pagina">
      <div className='aboutus'>
        <div className="aboutus-banner">
          <Typography variant="h1" component="h2" className='about-banner-title'>
            Contacto
          </Typography>

        </div>
        <div className="aboutus-text">
          <p>
            ¿Quieres contactar con nosotros?
          </p>
          <p>
            ¿Quieres montar un servicio similar a RepoSearch con tus propios documentos?
          </p>
          <p>
            ¿El repositorio de memorias de tu universidad no está indexado y te gustaría que lo estuviera?
          </p>
          <p>
            ¿Tu TFG está online pero no aparece en RepoSearch?
          </p>
          <Typography variant="h8" component="h2">
            ¡No dudes en ponerte en contacto con nosotros!
          </Typography>
        </div>
        <div className='aboutus-contact'>
          <Card sx={{ maxWidth: 345 }}>

            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Juan Alagon
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Estudiante de Ingeniería Informática en la facultad de Informática de la UPV/EHU
              </Typography>

            </CardContent>
            <CardActions>
              <p>
                Email: <a href="mailto: guskikalola@gmail.com">guskikalola@gmail.com</a>
              </p>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345 }}>

            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Juanan Pereira
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Profesor en la Facultad de Informática de la UPV/EHU
              </Typography>

            </CardContent>
            <CardActions>
              <p>
                Email: <a href="mailto: juanan.pereira@ehu.eus">juanan.pereira@ehu.eus</a>
              </p>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345 }}>

            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Ander Fernandez
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Ingeniero Informático graduado en la facultad  de Informática de la UPV/EHU
              </Typography>

            </CardContent>
            <CardActions>
              <p>
                Email: <a href="mailto: anderfernandez.ce@gmail.com">anderfernandez.ce@gmail.com</a>
              </p>
            </CardActions>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default About;
