import Grid from '@mui/material/Unstable_Grid2';

const WidgetGroup = ({ children }) => {

    return (
        <Grid className="widget-group" container xs={12}>
            {children}
        </Grid>);
}

export default WidgetGroup;