function getBoolean(key, defaultValue=false) {
    try {
        let parsed = JSON.parse(localStorage.getItem(key));
        if (typeof parsed != "boolean")
            throw "Not a valid type";
        return parsed;
    } catch (e) {
        return defaultValue;
    }
}

export {getBoolean};