import { useState } from 'react';
import StatusGauge from '../StatusGauge';

const EmbeddingStatusGauge = ({ data }) => {

  return (
    <StatusGauge
      value={data.total_embedded}
      valueMax={data.total_projects}
      title="Documentos con embedding generado"
    />
  );
}

export default EmbeddingStatusGauge;