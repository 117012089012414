import DropItem from '@components/DropItem';
import useFilters from '@stores/filters';
const AuthorFilterContent = ({ sectionName, visible, toggle, onFilterChange }) => {
  const authorName = useFilters((state) => state.autorNombre);
  const authorSurname = useFilters((state) => state.autorApellido);
    return (
      <>
        <DropItem sectionName={sectionName} toggle={toggle} visible={visible}></DropItem>
        <div className="droppableFilterContent" style={{ display: visible ? "flex" : "none" }}>
          <div className='droppableFilterContentWrapper'>
            <label>Buscar por Nombre</label>
            <input type="text" id="autorNombre" name="autorNombre" onChange={onFilterChange} placeholder="Introduce el nombre..." value={authorName}></input>
            <label>Buscar por apellido</label>
            <input type="text" id="autorApellido" name="autorApellido" onChange={onFilterChange} placeholder="Introduce el apellido..." value={authorSurname}></input>
            <div className='spacer'></div>
          </div>
  
        </div>
  
      </>
    )
  };

export default AuthorFilterContent;