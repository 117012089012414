import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Typography } from '@mui/material';

export default function DataChart({ data, title, ...param }) {
    return (
        <div className="data-chart">
            <Typography
                className="data-chart-title"
                variant="h6"
                component="p"
            >
                {title}
            </Typography>
            <PieChart
                series={[
                    {
                        data: data,
                    },
                ]}
                width={400}
                height={200}
                margin={{ right: 5 }}
                slotProps={{
                    legend: { hidden: true },
                }}
                {...param}
            />
        </div>
    );
}