export default function Memory({ item, handleOpen, opened }) {
    let metadata = () => {
      if (item.metadata != undefined) {
        return (<a role="link" onClick={(e) => handleOpen(e, item)}>Ver fuente <span className="more-info" title="Ver página y texto usados para dar este resultado.">(?)</span></a>);
      }
      else return null;
    }
    return <tr className={opened ? 'memory-opened' : ''}>
      <td>{item.name}</td>
      <td>{item.author}</td>
      <td>{item.date}</td>
      <td>{item.university.toUpperCase()}</td>
      <td>
      <div style={item.metadata ? { display: "flex", flexDirection: "column", gap: "1em" } : {}}><a href={item.link} target="_blank" rel="noreferrer">PDF</a>
        {metadata()}
      </div>
      </td>
    </tr>
  }