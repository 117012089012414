import { HiOutlinePlusSm, HiMinus } from 'react-icons/hi';

const DropItem = ({ sectionName, visible, toggle }) => {
    const Item = visible ? HiMinus : HiOutlinePlusSm
    return (
        <>
            <div className="droppableFilter" onClick={() => toggle(sectionName.replace("í", "i"))} // This is nothing but a hotfix.. TODO: Change this component to prevent the replace
                style={{
                    backgroundColor: visible ? '#f7f9fb' : '',
                    borderBottom: visible ? 'none' : ''
                }}>
                <div className='droppableFilterInside'>
                    <p>{sectionName}</p>
                    <Item style={{
                        fontSize: '25px',
                        color: '#1991eb',
                        transform: visible ? 'rotate(180deg)' : 'rotate(360deg)',
                        transition: 'all 0.2s'
                    }} />
                </div>

            </div>
        </>
    )
};

export default DropItem;