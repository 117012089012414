import { authenticate, logout, checkTokenValidity } from "@helpers/api";
import useAPI from '@stores/api';
import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';

const LoginForm = forwardRef((props, ref) => {

    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [error, setError] = useState("");

    const loginContainer = useRef(null);

    let setToken = useAPI((state) => state.setToken)
    let storedToken = useAPI((state) => state.token)

    async function onSubmit(e) {
        e.preventDefault();

        try {
            let token = await authenticate(username, password);
            if (token) {
                setToken(token);
                localStorage.setItem("token", token);
                closeModal();
            }
        } catch (e) {
            switch (e.code) {
                case 401:
                    setError("Credenciales incorrectas");
                    break;
                default:
                    setError("Error del servidor");
                    break;
            }
        }
    }

    function closeModal(e) {
        if (!e || e.target == loginContainer.current)
            loginContainer.current.classList.remove("open");
    }

    function openModal() {
        setError("");
        loginContainer.current.classList.add("open");
        loginContainer.current.scrollIntoView()
    }

    useImperativeHandle(ref, () => ({
        openModal,
        closeModal
    }));

    useEffect(() => {
        if (storedToken) {
            (async function () {
                let valid = await checkTokenValidity(storedToken);
                if (!valid)
                    logout();
            })();
        }
    });

    return (
        <div className="login-form-container" onClick={closeModal} ref={loginContainer}>
            <div className="login-form">
                <p className="form-title">Inicio de sesión administrativo</p>
                <form onSubmit={onSubmit}>
                    <div className="form-group">
                        <label>Usuario: </label>
                        <input required name="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label>Contraseña: </label>
                        <input required name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <button className="form-submit">Acceder</button>
                </form>
                <p className="form-error" style={{ display: error.length > 0 ? 'inline' : 'none' }}>{error}</p>
            </div>
        </div>
    )
});

export default LoginForm;