import { Typography } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { useState } from 'react';

const StatusGauge = ({ title, value, ...params }) => {

    return (
        <div className="status-gauge">
            <Typography className="status-gauge-title"
                variant="h6"
                component="p"
            >
                {title}
            </Typography>
            <Gauge
                value={value}
                text={
                    ({ value, valueMax }) => `${value} / ${valueMax}`
                }
                {...params}
                width={250}
                height={200}
            />
        </div>);
}

export default StatusGauge;