import DropItem from '@components/DropItem.jsx';

const UniversityFilterContent = ({ sectionName, visible, toggle, onFilterChange, universidades, allChecked }) => {
    return (
        <>
            <DropItem sectionName={sectionName} toggle={toggle} visible={visible}></DropItem>
            <div className="droppableFilterContent" style={{ display: visible ? "flex" : "none" }}>
                <div className='droppableFilterContentWrapper'>
                    <p>Buscar por Universidad</p>
                    <div className="universidadesCheckBox">
                        <div><input type="checkbox" className='checkboxStyle' checked={allChecked} name="checkall" onChange={onFilterChange} id="uni-chk-all" /> <label htmlFor="uni-chk-all" className="chk-label">{allChecked ? "Deseleccionar todas" : "Seleccionar todas"}</label></div>
                        {Object.entries(universidades).toSorted((([key_a,value_a], [key_b,value_b]) => key_a < key_b ? -1 : 1)).map(([key, value]) => {
                            return <div key={key}><input className='checkboxStyle' type="checkbox" name={key} checked={value} onChange={onFilterChange} id={`uni-chk-${key}`}/> <label className="chk-label" htmlFor={`uni-chk-${key}`}>{key}</label></div>
                        })}
                        <div className='spacer'></div>
                    </div>
                </div>

            </div>

        </>
    )
};

export default UniversityFilterContent;