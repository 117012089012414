import React from 'react';
import Pagination from '@mui/material/Pagination';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Memory from '@components/Memory';
import useAPI from '@stores/api';
import useFilters from '@stores/filters';
import { highlight } from '@helpers/strings';

export const RightPanel = () => {

  const data = useAPI((state) => state.data);
  const loading = useAPI((state) => state.loading);
  const responseCode = useAPI((state) => state.responseCode);
  const currentPage = useAPI((state) => state.currentPage);
  const orderBy = useFilters((state) => state.orderBy);
  const isHeaderClickable = useAPI((state) => state.searchMode != 'semantic');

  const setCurrentPage = useAPI((state) => state.setCurrentPage);
  const setFilters = useFilters((state) => state.setFilters);

  function onOrderByChange(event) {
    const copiaState = JSON.parse(JSON.stringify(orderBy));
    let order = event.target.getAttribute("name") ? event.target.getAttribute("name") : "date"
    copiaState.map((object) => {
      if (object.name === order) {
        object.is = true
        object.order = object.order === "desc" ? "asc" : "desc"
      }
      else {
        object.is = false
      }
      return object
    })
    setFilters({ orderBy: [...copiaState] });
  };
  function handlePageChange(e, value) {
    setCurrentPage(value);
  }

  const [semanticMemory, setSemanticMemory] = React.useState({});
  const [metadataModalOpen, setMetadataModalOpen] = React.useState(false);
  const handleOpen = (e, memory) => {
    setSemanticMemory(memory);
    setMetadataModalOpen(true);
  }
  const handleClose = () => setMetadataModalOpen(false);

  let serverError = responseCode != 200;
  let errorMessage = () => {
    switch (responseCode) {
      case 200:
        return "";
      case 429:
        return "Demasiadas peticiones. Espera antes de hacer mas."
      default: // default error
        return "Error del servidor..";
    }
  }

  return (
    <>
      <div id="rightPanel">
        <div id="rightPanelContent">
          <div className='title'>
            {/* <div onClick={() => { window.location.reload() }}>
              <h1 className='rightTitle'>
                <span className='titlecolor'>Repo</span>Search
              </h1>
            </div> */}
            <h2>
              <span className='titleDescription'>Buscador de TFGs de Ingeniería Informática</span>
            </h2>

          </div>

          <div id='tableContainer'>
            <div id='memories-container'>
              <table id="memories">
                <thead>
                  <tr>
                    {orderBy.map(item => <th key={item.name} name={item.name} onClick={!isHeaderClickable ? null : onOrderByChange} className={(item.is ? `sorting_active_${item.order}` : "sorting_disabled") + (!isHeaderClickable ? " no_sortable" : "")}>{item.th}</th>)}
                    <th>Memoria</th>
                  </tr>
                </thead>
                <tbody>
                  {(!("totalMemories" in data)) || (data.totalMemories !== undefined && data.totalMemories === 0) ? <tr><td colSpan={5}>No se han encontrado resultados</td></tr> : data.memories.map(item =>
                    <Memory key={item.id} item={item} handleOpen={handleOpen} opened={metadataModalOpen && semanticMemory?.id == item.id} />
                  )
                  }</tbody>
              </table>
            </div>
            <div id="procesando" style={loading && !serverError ? { display: 'flex' } : { display: 'none' }}><h2>Procesando...</h2></div>
            <div id="error" style={serverError ? { display: 'flex' } : { display: 'none' }}><h2>{errorMessage()}</h2></div>
            <div className='paginationWrapper'>
              <span> Mostrando un total de {data.totalMemories || 0} resultados </span>
              <ul>
                <Pagination count={!("totalMemories" in data) ? 0 : data.totalPages} onChange={handlePageChange} page={currentPage} siblingCount={3} color='primary' />
              </ul>
            </div>

            <Modal
              open={metadataModalOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className="modal">
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Metadata de la búsqueda semántica.
                </Typography>

                <div id="modal-modal-description" sx={{ mt: 2 }}>
                  <p className="ellipsis-overflow">Memoria: {semanticMemory?.name}</p>
                  <p>Página: {semanticMemory?.metadata?.page + 1}</p>
                  Texto:
                  <div className="highlight-container">
                    {highlight(semanticMemory?.metadata?.text)}
                  </div>
                </div>
              </Box>
            </Modal>

          </div>


        </div>
      </div>



    </>
  )
}