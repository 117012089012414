import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
/**
 * 
 * @param {{data: import('src/pages/admin').QueryOverview}} param0 
 * @returns 
 */
export default function QueriesStatisticsDataGrid({ data }) {

    let columns = [
        {
            field: "id",
            headerName: "ID",
            description: '',
            sortable: true,
            width: 90
        },
        {
            field: "type",
            headerName: "Tipo",
            description: "Tipo de consulta",
            sortable: true,
            width: 90
        },
        {
            field: "date",
            headerName: "Fecha",
            description: "Fecha de la consulta",
            sortable: true,
            width: 110
        },
        {
            field: "page",
            headerName: "N. Página",
            description: "Número de página",
            sortable: true,
            type: 'number',
            width:90
        },
        {
            field: "universities",
            headerName: "Universidades",
            description: "URL de la consulta",
            sortable: false,
            editable: true,
            width: 220
        },
        {
            field: "dateOperator",
            headerName: "Operador fecha",
            description: "",
            sortable: false,
            editable: false,
            width: 120
        },
        {
            field: "dateFilter",
            headerName: "Fecha filtro",
            description: "",
            sortable: false,
            editable: false,
            width: 120
        },
        {
            field: "title",
            headerName: "Titulo documento",
            description: "",
            sortable: true,
            editable: true,
            width: 220
        },
        {
            field: "semanticQuery",
            headerName: "Búsqueda semántica",
            description: "",
            sortable: true,
            editable: true,
            width: 220
        },
        {
            field: "author",
            headerName: "Autor",
            description: "",
            sortable: true,
            editable: false,
            width: 220
        },
        {
            field: "url",
            headerName: "URL",
            description: "URL de la consulta",
            sortable: false,
            editable: true,
            renderCell: (url) => (<a href={url.value}>URL</a>)
        },
    ];

    let rows = !data ? [] : data.queries.map(v => v.queries).flat().map((v,index) => {
        let url = new URL(v.url);

        let author_full_name = "";
        if(url.searchParams.get("author_name"))
            author_full_name += url.searchParams.get("author_name")

        if(url.searchParams.get("author_last_name"))
            author_full_name += " " + url.searchParams.get("author_last_name")

        let dateFilter = url.searchParams.get("dt");
        let dateOperator = dateFilter ? url.searchParams.get("date_operator") : "";

        return {
            id: index,
            type: v.type,
            date: v.date,
            url: url.href,
            page: url.searchParams.get("page"),
            universities: url.searchParams.getAll("universities").join(", "),
            dateOperator: dateOperator,
            dateFilter: dateFilter,
            title: url.searchParams.get("name"),
            semanticQuery: url.searchParams.get("query"),
            author: author_full_name,
        }
    });

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                columnBufferPx={100}
                disableRowSelectionOnClick
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
            />
        </div>
    );
}