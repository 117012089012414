import { useState } from 'react';
import StatusGauge from '../StatusGauge';

const DownloadedGauge = ({ data }) => {

  return (
    <StatusGauge
      value={data.total_downloaded}
      valueMax={data.total_projects}
      title="Documentos descargados"
    />
  );
}

export default DownloadedGauge;