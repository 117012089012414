import { create } from 'zustand'

const initialState = {
    searchMode: "metadata",
    semanticQuery: "", // semanticSearch
    data: {
        totalMemories: 0,
        totalPages: 0,
        memories: []
    },
    loading: false,
    responseCode: 200,
    currentPage: 1,
    lastRequestTS: 0,
    token: localStorage.getItem("token")
}

const useStore = create((set) => ({
    // State
    ...initialState,

    // Methods
    setSearchMode: (mode) => set({ searchMode: mode }),
    setSemanticQuery: (query) => set({ semanticQuery: query }),
    setData: (data) => set({data : data}),
    setLoading: (loading) => set({loading: loading}),
    setCurrentPage: (page) => set({currentPage: page}),
    setResponseCode: (code) => set({responseCode: code}),
    setLastRequestTS: (timestamp) => set({lastRequestTS: timestamp}),
    setToken: (token) => set({token: token}),
    reset: () => set(initialState),
}))

export default useStore;