import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import usePrivacy from '@stores/privacy';
import Switch from '@mui/material/Switch';
import { getBoolean } from '@helpers/storage';


export default function DataUsageWarn() {
    const open = usePrivacy((state) => state.warnOpen);
    const setOpen = usePrivacy((state) => state.setWarnOpen);

    const storeVisit = usePrivacy((state) => state.storeVisit);
    const setStoreVisit = usePrivacy((state) => state.setStoreVisit);

    const storeQueries = usePrivacy((state) => state.storeQueries);
    const setStoreQueries = usePrivacy((state) => state.setStoreQueries);
    let warnEverOpened;

    // NOTE: Uncomment to enable opening on first visit 
    // warnEverOpened = getBoolean("privacy.warnEverOpened");
    // if (!warnEverOpened) {
    //     localStorage.setItem("privacy.warnEverOpened", true);
    //     setOpen(true);
    // }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const options = [
        {
            "name": "Registrar visita",
            "description": "Registrar tu visita cada vez que entras a la página web. Se almacena tu dirección IP y fecha de la visita.",
            "value": storeVisit,
            "updateFunction": (e) => { if (e.target) setStoreVisit(e.target.checked) },
        },
        {
            "name": "Registrar búsquedas",
            "description": "Registrar las búsquedas que haces dentro de RepoSearch. Se almacena la búsqueda y fecha de la búsqueda.",
            "value": storeQueries,
            "updateFunction": (e) => { if (e.target) setStoreQueries(e.target.checked) },
        },
    ];

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Ajustes de privacidad"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Configura aquí los ajustes relacionados con tu privacidad.
                        Usamos tus datos para entender el uso del servicio y no los compartimos con terceros. Son de uso interno.
                    </DialogContentText>
                    <div className="switch-group">
                        {
                            options.map((option, index) => (
                                <div key={index} className="switch-group-item">
                                    <div>
                                        <label className="switch-group-item-title" htmlFor={`privacy-${index}`}>{option.name}</label>
                                        <p className="switch-group-item-description">
                                            {option.description}
                                        </p>
                                    </div>
                                    <Switch id={`privacy-${index}`} checked={option.value} onChange={option.updateFunction} />
                                </div>
                            ))
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}