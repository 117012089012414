import React, { useState, useEffect } from 'react';
import useFilters from '@stores/filters';
import useAPI from '@stores/api';
import TitleFilterContent from '@components/TitleFilterContent.jsx';
import DateFilterContent from '@components/DateFilterContent.jsx';
import AuthorFilterContent from '@components/AuthorFilterContent.jsx';
import UniversityFilterContent from '@components/UniversityFilterContent.jsx';


let onSemanticSearch; // Function
let onMetadataSearch; // Function
let setActiveFilterSection; // Function
let activeFilterSectionC = "semantic"; // TODO: This should be moved to a store to sync between the mobile and desktop version of side-menu

function handleKeyPress(e) {
  let key = e.key;
  let target = e.target;
  let container = document.querySelector("#left");
  let container2 = document.querySelectorAll("#leftPanelContent").item(1);
  // Only accept the enter key and only if target is inside the left panel
  if (key !== "Enter" || !container || !container2 || (!container.contains(target) && !container2.contains(target))) return;

  if (!(target instanceof HTMLInputElement) || target.type !== "text") return;

  // Only one will activate, the one whose tab is active
  if (activeFilterSectionC == "metadata") {
    onMetadataSearch();
  } else {
    onSemanticSearch();
  }
}

export const LeftPanel = () => {

  const allChecked = useFilters((state) => state.allChecked);
  const universidades = useFilters((state) => state.filter_universidades);
  const filterState = useFilters(state => state);

  const semanticQuery = useAPI((state) => state.semanticQuery);
  const currentPage = useAPI((state) => state.currentPage);

  const setFilter = useFilters((state) => state.setFilter);
  const setFilters = useFilters((state) => state.setFilters);

  const setSemanticQuery = useAPI((state) => state.setSemanticQuery);
  const setSearchMode = useAPI((state) => state.setSearchMode);
  const setLastRequestTS = useAPI((state) => state.setLastRequestTS);
  const setCurrentPage = useAPI((state) => state.setCurrentPage);

  const resetFilters = useFilters((state) => state.reset);




  onMetadataSearch = function () {
    setSearchMode("metadata");
    setLastRequestTS(Date.now());
    if (currentPage != 1) setCurrentPage(1);
    // setIsHeaderClickable(true)
  }
  onSemanticSearch = function () {
    if (semanticQuery.length == 0) return;
    setSearchMode("semantic");
    setLastRequestTS(Date.now());
    if (currentPage != 1) setCurrentPage(1);
    // setIsHeaderClickable(false)
  }

  function onSemanticSearchChange(event) {
    setSemanticQuery(event.target.value)
  };

  // function to handle text input change
  function onFilterChange(event) {
    setFilter(event.target.name, event.target.value);
  };

  // function to handle checkbox  change
  function onCheckboxChange(event) {
    const copiaState = JSON.parse(JSON.stringify(filterState));
    if (event.target.name === "checkall") {
      let checked = event.target.checked
      Object.entries(copiaState.filter_universidades).map(([key, value]) => {
        return copiaState.filter_universidades[key] = checked
      })
    }
    else {
      copiaState.filter_universidades[event.target.name] = event.target.checked
    }
    let allChecked = Object.entries(copiaState.filter_universidades).every(([key, value]) => {
      return value === true
    })
    copiaState.allChecked = allChecked
    setFilter("allChecked",allChecked);
    setFilter("filter_universidades",copiaState.filter_universidades);
    // setFilters({ ...copiaState })
  }

  const [dropState, setDropState] = useState({
    Titulo: false,
    Fecha: false,
    Autor: false,
    Universidad: false
  });

  let [activeFilterSection, setActiveFilterSection] = useState('semantic')
  const handleTabClick = (tab) => {
    setActiveFilterSection(tab);
    activeFilterSectionC = tab;
  };

  const drop = (seccion) => {
    const copiaState = { ...dropState }
    copiaState[seccion] = !copiaState[seccion]
    setDropState({ ...copiaState })
  };

  // Event used to send the search request when pressing enter in text fields, kinda ugly but better than duplicating code for each input
  document.addEventListener("keyup", handleKeyPress);

  return (
    <>
      <div id="leftPanel">
        <div className='filter-headers'>
          <div className='filter-headers-content'>
            <h2>Filtros</h2>
            <button className='reset-btn' onClick={resetFilters}>Borrar filtros</button>
          </div>
        </div>
        <div className='filter-sections'>
          <section onClick={() => handleTabClick('metadata')} className={`seccion ${activeFilterSection === 'metadata' ? 'active' : ''}`}>
            <span >Búsqueda por Metadata</span>
          </section>
          <section onClick={() => handleTabClick('semantic')} className={`seccion ${activeFilterSection === 'semantic' ? 'active' : ''}`}>
            <span >Búsqueda Semántica</span>
          </section>

        </div>
        <div id="leftPanelContent">

          <div id='metadata-filter' style={{ display: activeFilterSection === 'metadata' ? 'flex' : 'none' }}>
            <TitleFilterContent sectionName="Título" toggle={drop} visible={dropState.Titulo} onFilterChange={onFilterChange}></TitleFilterContent>
            <DateFilterContent sectionName="Fecha" toggle={drop} visible={dropState.Fecha} onFilterChange={onFilterChange}></DateFilterContent>
            <AuthorFilterContent sectionName="Autor" toggle={drop} visible={dropState.Autor} onFilterChange={onFilterChange}></AuthorFilterContent>
            <UniversityFilterContent sectionName="Universidad" toggle={drop} visible={dropState.Universidad} onFilterChange={onCheckboxChange} universidades={universidades} allChecked={allChecked}></UniversityFilterContent>
            <div className='search'>
              <button id='metadata-search' onClick={onMetadataSearch}> Buscar</button>
            </div>

          </div>
          <div id='semantic-filter' style={{ display: activeFilterSection === 'semantic' ? 'flex' : 'none' }}>

            {/* <form onSubmit={onSemanticSearch}> */}
            <div className='semantic_section'>
              <label>Búsqueda semántica: permite buscar por conceptos, como por ejemplo, inteligencia artificial.</label>
              <input type="text" id="semanticSearch" name="semanticSearch" placeholder="Introduce el concepto..." onChange={onSemanticSearchChange}></input>
              <DateFilterContent sectionName="Fecha" toggle={drop} visible={dropState.Fecha} onFilterChange={onFilterChange}></DateFilterContent>
              <UniversityFilterContent sectionName="Universidad" toggle={drop} visible={dropState.Universidad} onFilterChange={onCheckboxChange} universidades={universidades} allChecked={allChecked}></UniversityFilterContent>
            </div>
            {/* </form> */}
            <div className='search'>
              <button id='semantic-search' onClick={onSemanticSearch}> Buscar</button>
            </div>

          </div>


        </div>
      </div>

    </>
  )
};