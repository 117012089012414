import { getOverview } from '@helpers/api';
import { useEffect, useState } from 'react';
import useAPI from '@stores/api';
import Grid from '@mui/material/Unstable_Grid2';
import UserStatisticsLineChart from '@components/statistics/users/UserStatisticsLineChart';
import QueriesStatisticsLineChart from '@components/statistics/queries/QueriesStatisticsLineChart';
import QueriesStatisticsDataGrid from '@components/statistics/queries/QueriesStatisticsDataGrid';
import EmbeddingStatusGauge from '@components/statistics/embedding/EmbeddingStatusGauge';
import ConditionalRender from '@components/ConditionalRender';
import WidgetGroup from '@components/statistics/WidgetGroup';
import MissingEmbeddingsChart from '@components/statistics/embedding/MissingEmbeddingsChart';
import DownloadedGauge from '@components/statistics/embedding/DownloadedGauge';
import FrontendErrorsDataGrid from '@components/statistics/errors/QueriesStatisticsDataGrid';

/**
 * @typedef EmbeddingOverview
 * @property {number} total_downloaded 
 * @property {number} total_embedded 
 * @property {number} total_projects 
 * @property {number} total_not_available 
 * @property {string} last_check 
 * @property {Object[]} status
 * @property {string} status[].university
 * @property {number} status[].projects
 * @property {number} status[].downloaded
 * @property {number} status[].embedded
 * @property {number} status[].not_available
 * @property {string} status[].last_check
 */

/**
 * @typedef QueryOverview
 * @property {Object[]} queries
 * @property {string} queries[].date
 * @property {Object[]} queries[].queries
 * @property {number} queries[].queries[].id
 * @property {string} queries[].queries[].date
 * @property {"mixed"|"semantic"|"memories"} queries[].queries[].type
 * @property {string} queries[].queries[].url
 */

/**
 * @typedef VisitOverview 
 * @property {number} total_new_users 
 * @property {number} total_users
 * @property {Object[]} visits
 * @property {string} visits[].date
 * @property {number} visits[].total_users
 * @property {number} visits[].new_users
 */

/**
 * @typedef FrontErrorOverview 
 * @property {Object[]} errors
 * @property {number} errors[].id
 * @property {number} errors[].error_code
 * @property {string} errors[].error_message
 * @property {string} errors[].url
 * @property {string} errors[].date
 */

const Admin = () => {

    let token = useAPI((state) => state.token);

    /** @type {[EmbeddingOverview,React.Dispatch<React.SetStateAction<{}>>]} */
    let [embeddings, setEmbeddings] = useState(undefined);

    /** @type {[QueryOverview,React.Dispatch<React.SetStateAction<{}>>]} */
    let [queries, setQueries] = useState(undefined);

    /** @type {[VisitOverview,React.Dispatch<React.SetStateAction<{}>>]} */
    let [visits, setVisits] = useState(undefined);

    /** @type {[FrontErrorOverview,React.Dispatch<React.SetStateAction<{}>>]} */
    let [errors, setErrors] = useState(undefined);


    function fetchData() {
        Promise.all([getOverview("embedding", token), getOverview("query", token), getOverview("visit", token), getOverview("fronterror", token)])
            .then((values) => {
                setEmbeddings(values[0]);

                setQueries(values[1]);

                setVisits(values[2]);

                setErrors(values[3]);
            })
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="pagina statistics-page">
            <Grid container>
                <ConditionalRender condition={embeddings != undefined && visits != undefined && queries != undefined}>
                    <WidgetGroup>
                        <UserStatisticsLineChart data={visits} />
                        <QueriesStatisticsLineChart data={queries} />
                        <WidgetGroup>
                            <p>Total de usuarios: {visits?.total_users}</p>
                        </WidgetGroup>
                        <WidgetGroup>
                            <p>Total de usuarios nuevos: {visits?.total_new_users}</p>
                        </WidgetGroup>
                    </WidgetGroup>
                    <WidgetGroup>
                        <div className="queries-statistics-data-grid">
                            <QueriesStatisticsDataGrid data={queries} />
                        </div>
                    </WidgetGroup>
                    <WidgetGroup>
                        <EmbeddingStatusGauge data={embeddings} />
                        <MissingEmbeddingsChart data={embeddings} />
                        <DownloadedGauge data={embeddings} />
                    </WidgetGroup>
                    <WidgetGroup>
                        <p>Última comprobación de disponibilidad: {embeddings?.last_check.replace("T", " ")}</p>
                    </WidgetGroup>
                    <WidgetGroup>
                        <div className="queries-statistics-data-grid">
                            <FrontendErrorsDataGrid data={errors} />
                        </div>
                    </WidgetGroup>
                </ConditionalRender>
            </Grid>
        </div>
    )
}

export default Admin;
