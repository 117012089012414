import DropItem from "@components/DropItem";
import useFilters from '@stores/filters';

const TitleFilterContent = ({ sectionName, visible, toggle, onFilterChange }) => {
    const title = useFilters((state) => state.titulo);
    return (
      <>
        <DropItem sectionName={sectionName} toggle={toggle} visible={visible} ></DropItem>
        <div className="droppableFilterContent" style={{ display: visible ? "flex" : "none" }}>
          <div className='droppableFilterContentWrapper'>
            <label>Buscar por título</label>
            <input type="text" id="titulo" name="titulo"  value={title} onChange={onFilterChange} placeholder="Introduce el título..."></input>
            <div className='spacer'></div>
          </div>
  
        </div>
  
      </>
    )
  };

export default TitleFilterContent;