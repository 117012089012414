import { LineChart } from '@mui/x-charts';
import { compareDatedObjects } from '@helpers/date';

const UserStatisticsLineChart = ({ data }) => {

    return (
        <div className='linechart-with-label'>
            <LineChart
                xAxis={[{
                    id: 'Fecha',
                    data: data ? data.visits.sort(compareDatedObjects).map(v => new Date(v.date)) : [new Date()],
                    scaleType: "time",
                    valueFormatter: (date) => date.toISOString().split('T')[0],
                }]}
                series={[
                    {
                        data: data ? data.visits.sort(compareDatedObjects).map(v => v.new_users) : [0],
                        label: "Nuevos usuarios",
                        id: "Nuevos usuarios",
                    },
                    {
                        data: data ? data.visits.sort(compareDatedObjects).map(v => v.total_users) : [0],
                        label: "Usuarios totales",
                        id: "Usuarios totales",
                    }
                ]}
                width={500}
                height={300}
            />
            <p>
                Número de visitas por día
            </p>
        </div>
    );
};

export default UserStatisticsLineChart;