import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';

/**
 * 
 * @param {{data: import('src/pages/admin').FrontErrorOverview}} param0 
 * @returns 
 */
export default function FrontendErrorsDataGrid({ data }) {

    let columns = [
        {
            field: "id",
            headerName: "ID",
            description: '',
            sortable: true,
            width: 90
        },
        {
            field: "error_code",
            headerName: "Codigo Err.",
            description: "Código del error HTTP",
            sortable: false,
            type: "number",
            width: 90
        },
        {
            field: "error_message",
            headerName: "Mensage Err.",
            description: "Mensaje del error",
            sortable: false,
            editable: true,
            width: 320
        },
        {
            field: "date",
            headerName: "Fecha",
            description: "Fecha del error",
            sortable: true,
            width: 110,
            valueFormatter: (date) => date.split('T')[0],
        },
        {
            field: "url",
            headerName: "URL",
            description: "URL donde se ha dado el error",
            sortable: false,
            editable: true,
            width: 320
        },
    ];

    let rows = !data ? [] : data.errors;

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                columnBufferPx={100}
                disableRowSelectionOnClick
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'date', sort: 'desc' }],
                    },
                  }}
            />
        </div>
    );
}