import { create } from 'zustand'
import { produce } from 'immer'

const initialState = {
    titulo: "",
    fecha: "",
    autorNombre: "",
    autorApellido: "",
    fechaOperacion: ">=",
    universidades: [],
    filter_universidades: {

    },
    allChecked: true,
    orderBy: [
        { name: "name", is: false, order: "desc", th: "Nombre de la memoria" },
        { name: "author", is: false, order: "desc", th: "Autor" },
        { name: "date", is: true, order: "desc", th: "Fecha" },
        { name: "university", is: false, order: "desc", th: "Universidad" }
    ]
}

const useStore = create((set) => ({

    // State
    ... initialState,

    // Methods
    setFilter: (key, value) => set(produce((state) => { state[key] = value })),
    setFilters: (filters) => set(filters),
    reset: () => {
        set((state) => {
            return {
                ...initialState,
                universidades: [...state.universidades],
                orderBy: state.orderBy,
                filter_universidades: Object.keys(state.filter_universidades).reduce((acc, key) => {
                    acc[key] = true;
                    return acc;
                }, {}),
            };
        });
    },
}))

export default useStore;