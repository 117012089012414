import React, { useEffect, useRef } from 'react';
import { LeftPanel } from '@components/LeftPanel';
import { RightPanel } from '@components/RightPanel';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HTTPException from '../exceptions/HTTPException.js';
import useFilters from '@stores/filters';
import useAPI from '@stores/api';
import usePrivacy from '@stores/privacy';
import { getUniversities, getFilteredData, getSemanticData, getUrlMetadata, getUrlSemantic, storeError } from '@helpers/api';

const Home = () => {
  const filterState = useFilters(state => state);
  const data = useAPI((state) => state.data);
  const orderBy = useFilters((state) => state.orderBy);
  const currentPage = useAPI((state) => state.currentPage);
  const searchMode = useAPI((state) => state.searchMode);
  const semanticQuery = useAPI((state) => state.semanticQuery);
  const lastRequestTS = useAPI((state) => state.lastRequestTS);
  const registerQuery = usePrivacy((state) => state.storeQueries);

  const setCurrentPage = useAPI((state) => state.setCurrentPage);
  const setFilters = useFilters((state) => state.setFilters);
  const setLoading = useAPI((state) => state.setLoading);
  const setResponseCode = useAPI((state) => state.setResponseCode);

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const drawerRef = useRef(null);

  // function that fetches data from backend
  const setData = useAPI((state) => state.setData);
  let lastURL = "";
  const getData = function (mode) {
    let url = "";
    setLoading(true);

    // Close the side panel on mobiles when performing a query
    if (mobileOpen && drawerRef.current) {
      let component = window.getComputedStyle(drawerRef.current, null);
      if (component && component.display != "none")
        handleDrawerClose();
    }

    if (mode == "metadata" || mode == undefined && searchMode === "metadata") {
      url = getUrlMetadata(filterState, currentPage);
      lastURL = url;
      return getFilteredData(url, registerQuery)
    }
    else if (mode == "semantic" || mode == undefined) {
      url = getUrlSemantic(filterState, currentPage, semanticQuery);
      lastURL = url;
      return getSemanticData(url, registerQuery);
    }
  }

  function updateData(mode) {
    getData(mode)
      .then(json => {
        setData(json);
        setLoading(false);
        setResponseCode(200);
      })
      .catch(err => {
        setLoading(false);
        if (err instanceof HTTPException) {
          if (err.code == 404) {
            setData({
              totalMemories: 0,
              totalPages: 0,
              memories: []
            })
          }
          else {
            setResponseCode(err.code);
            storeError(err.code, err.message, lastURL);
          }
        }
        else {
          setResponseCode(500);
          storeError(500, "Internal server error", lastURL);
        }
      })
  }

  function updateUniversities(universidades) {
    const copiaState = { ...filterState }
    copiaState.universidades = universidades
    for (const element of universidades) {
      copiaState.filter_universidades[element] = true
    }
    setFilters({ ...copiaState })
  }

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  const drawer = <LeftPanel />;

  //cuando iniciamos el componente, obtener las universidades a filtrar
  useEffect(() => {
    getUniversities()
      .then(json => {
        updateUniversities(json.universities);
      })
      .catch(err => {
        if (err instanceof HTTPException) {
          setResponseCode(err.code);
          storeError(err.code, err.message, `/api/memories/universities`);
        }
        else {
          setResponseCode(500);
          storeError(500, "Internal server error", `/api/memories/universities`);
        }
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // cuando cambie la data comprobar que la pagina actual no está fuera de rango
  useEffect(() => {
    if (currentPage > data.totalPages) {
      setCurrentPage(1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // when currentPage changes calls to fecth function 
  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, lastRequestTS]);

  // when orderby changes resets current page and calls to fecth function 
  useEffect(() => {
    if (currentPage != 1) {
      setCurrentPage(1)
    } else {
      updateData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy]);



  return (
    <>
      <div className="pagina">
        <div id="left">
          <div className="drawer-button">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: 'none' } }}
            >
              <MenuIcon />
            </IconButton>

          </div>

          {/* Mobile side panel */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', lg: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            }}
            ref={drawerRef}
          >
            {drawer}
          </Drawer>

          {/* Desktop side panel */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', lg: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box' },
            }}
            open
          >
            {drawer}
          </Drawer>
        </div>

        <RightPanel />
      </div>
    </>
  )
}

export default Home
