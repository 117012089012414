import HTTPException from "@exceptions/HTTPException";
import { getBoolean } from "./storage";

function getUrlMetadata(filterState, currentPage) {
    const universidades = []
    Object.entries(filterState.filter_universidades).forEach(([key, value]) => {
        if (value) { universidades.push(key) }
    })
    // Convert the list to a string with multiple values for the 'items' parameter
    const universitiesQueryParam = universidades.map(universidad => `universities=${encodeURIComponent(universidad)}`).join('&');
    const filteredOrder = filterState.orderBy.find((item) => {
        return item.is === true
    })
    let order = filteredOrder ? filteredOrder.name : "date"
    let orderType = filteredOrder ? filteredOrder.order : "desc"
    const baseUrl = `/api/memories/`;

    // Initialize an empty array to store URL parameters
    const queryParams = [];

    // Check if filterState.titulo is not empty and add it to the queryParams array
    if (filterState.titulo) {
        queryParams.push(`name=${encodeURIComponent(filterState.titulo)}`);
    }

    // Check if filterState.autorNombre is not empty and add it to the queryParams array
    if (filterState.autorNombre) {
        queryParams.push(`author_name=${encodeURIComponent(filterState.autorNombre)}`);
    }

    // Check if filterState.autorApellido is not empty and add it to the queryParams array
    if (filterState.autorApellido) {
        queryParams.push(`author_last_name=${encodeURIComponent(filterState.autorApellido)}`);
    }

    // Check if filterState.fecha is not empty and add it to the queryParams array
    if (filterState.fecha) {
        queryParams.push(`dt=${encodeURIComponent(filterState.fecha)}`);
    }

    // Check if filterState.fechaOperacion is not empty and add it to the queryParams array
    if (filterState.fechaOperacion) {
        queryParams.push(`date_operator=${encodeURIComponent(filterState.fechaOperacion)}`);
    }

    queryParams.push(universitiesQueryParam);
    // Add the remaining fixed parameters to the queryParams array
    queryParams.push(`order_by_column=${order}`);
    queryParams.push(`order_type=${orderType}`);
    queryParams.push(`page=${currentPage}`);

    // Combine the base URL and the queryParams into the final URL
    const url = baseUrl + '?' + queryParams.join('&');
    return url;
}

function getUrlSemantic(filterState, currentPage, semanticSearch) {
    let filters = [];

    const universidades = []
    Object.entries(filterState.filter_universidades).forEach(([key, value]) => {
        if (value) { universidades.push(key) }
    })
    // Convert the list to a string with multiple values for the 'items' parameter
    const universitiesQueryParam = universidades.map(universidad => `universities=${encodeURIComponent(universidad)}`).join('&');
    filters.push(universitiesQueryParam);

    // Check if filterState.fecha is not empty and add it to the filters array
    if (filterState.fecha) {
        filters.push(`dt=${encodeURIComponent(filterState.fecha)}`);
    }

    // Check if filterState.fechaOperacion is not empty and add it to the filters array
    if (filterState.fechaOperacion) {
        filters.push(`date_operator=${encodeURIComponent(filterState.fechaOperacion)}`);
    }

    const filters_s = filters.length > 0 ? "&" + filters.join("&") : "";

    const url = `/api/mixed/?query=${encodeURIComponent(semanticSearch)}&page=${currentPage}${filters_s}`;

    return url;
}

// Fetch data using the metadata endpoint
function getFilteredData(url, register = true) {
    // setLoading(true)
    return new Promise((resolve, reject) => {

        fetch(url, {
            headers: {
                "X-Privacy-Register-Query": register 
            }
        })
            .then(function (response) {
                if (!response.ok || response.status == 404) {
                    throw new HTTPException("Failed with HTTP code " + response.status, response.status);
                }
                return response.json();
            })
            .then((json) => {
                // setResponseCode(200)
                // setData(json)
                // setLoading(false)
                resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });

}

// Fetch data using the semantic search endpoint
function getSemanticData(url, register = true) {
    // console.log(semanticSearch)
    // setLoading(true)
    return new Promise((resolve, reject) => {

        fetch(url, {
            headers: {
                "X-Privacy-Register-Query": register 
            }
        })
            .then(function (response) {
                if (!response.ok || response.status == 404) {
                    throw new HTTPException("Failed with HTTP code " + response.status, response.status);
                }
                return response.json();
            })
            .then((json) => {
                // setResponseCode(200);
                // setData(json)
                // setLoading(false)
                resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

async function getUniversities() {
    const url = `/api/memories/universities`
    // setLoading(true)
    return new Promise((resolve, reject) => {
        fetch(url)
            .then(function (response) {
                if (!response.ok || response.status == 404) {
                    throw new HTTPException("Failed with HTTP code " + response.status, response.status);
                }
                return response.json();
            })
            .then((json) => {
                // setResponseCode(200)
                // updateUniversities(json.universities)
                // setLoading(false)
                resolve(json);
            })
            .catch(error => {
                reject(error);
            });
    });
}

/**
 * 
 * @param {string} username 
 * @param {string} password 
 * @returns {string} If successful, authentication token. Else false.
 */
async function authenticate(username, password) {
    const url = `/api/token`;

    return new Promise((resolve, reject) => {
        let form = new FormData();
        form.set("username", username);
        form.set("password", password);
        fetch(url, {
            method: "POST",
            body: form
        })
            .then(function (response) {
                if (!response.ok || response.status == 404) {
                    throw new HTTPException("Failed with HTTP code " + response.status, response.status);
                }
                return response.json();
            })
            .then((json) => {
                resolve(json.access_token);
            })
            .catch(error => {
                reject(error);
            });
    });
}

async function checkTokenValidity(token) {
    const url = `/api/users/me`;


    return new Promise((resolve, reject) => {
        if (!token) {
            resolve(false);
            return;
        }


        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(function (response) {
                if (!response.ok && response.status != 401) {
                    throw new HTTPException("Failed with HTTP code " + response.status, response.status);
                }
                return response.status;
            })
            .then((status) => {
                if (status == 200) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch(error => {
                reject(error);
            });
    });
}

function logout() {
    localStorage.removeItem("token");
    window.location.href = "/";
}

function registerVisit() {
    const url = `/api/statistics/visit`;

    if (!getBoolean("privacy.storeVisit", true))
        return;

    fetch(url, {
        method: "POST"
    });
}

/**
 * Calls to the statistics endpoint and gets
 * an overview of requested type
 * @param {"visit"|"query"|"embedding"|"fronterror"} type
 */
function getOverview(type, token) {
    let fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 20);
    let toDate= new Date();

    let queryParams = `from_date=${fromDate.toISOString().split('T')[0]}&to_date=${toDate.toISOString().split('T')[0]}`;

    const url = `/api/statistics/${type}/overview?${queryParams}`;

    return new Promise((resolve, reject) => {

        if (!token) {
            resolve({});
            return;
        }

        fetch(url, {
            method: "GET",
            headers: {
                "Authorization": "Bearer " + token
            }
        })
            .then(function (response) {
                if (!response.ok && response.status != 401) {
                    throw new HTTPException("Failed with HTTP code " + response.status, response.status);
                }
                return response.json();
            })
            .then((overview) => {
                resolve(overview);
            })
            .catch(error => {
                reject(error);
            });
    });
}

function storeError(errorCode,errorMessage,failedUrl) {

    if (errorCode == 429) return;

    const url = `/api/getMessage`;

    let data = {
        error_code: errorCode,
        error_message: errorMessage,
        url: failedUrl
    }
    return new Promise((resolve, reject) => {
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then(function (response) {
                if (!response.ok && response.status != 401) {
                    throw new HTTPException("Failed with HTTP code " + response.status, response.status);
                }
                return response.json();
            })
            .then((res) => {
                resolve(res);
            })
            .catch(error => {
                reject(error);
            });
    });
}

export { getFilteredData, getSemanticData, getUniversities, getUrlMetadata, getUrlSemantic, authenticate, logout, checkTokenValidity, registerVisit, getOverview, storeError };