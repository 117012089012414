import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import DataChart from '../DataChart';

export default function MissingEmbeddingsChart({ data }) {
    return (
        <DataChart
            title="Documentos no disponibles"
            data={data.status.map((v, index) => {
                return { id: index, label: v.university, value: v.not_available }
            })}
        />
    );
}