// Function to check similarity between two strings
// https://stackoverflow.com/a/36566052
function similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
  }
  
  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();
  
    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0)
          costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0)
        costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }
  
  function highlight(text) {
    if (!text) return <span></span>;
  
    let semanticSearch = document.getElementById("semanticSearch");
  
    let q = "";
    if (semanticSearch != undefined) {
      q = semanticSearch.value;
    }
  
    let querySplitted = q.toLowerCase().split(/\s/).filter(e => e.length > 3);
  
    return <p>{text.split(/\s/).map((word, index) => {
      // if (q.includes(word.replace(/([,.!?;¿¡'\"\\-])+/g, ""))) {
      let wordCleaned = word.toLowerCase().replace(/([,.!?;¿¡'\"\\-])+/g, ""); // Remove punctuation
      if (querySplitted.findIndex(e => similarity(e, wordCleaned) >= 0.5625) != -1 && wordCleaned.length > 3) {
        return (<span key={index} className="highlighted-word"> {word} </span>)
      }
      else {
        return (<span key={index}> {word} </span>)
      }
    })}</p>
  }

export {highlight};